var scrollReveal = require('scrollreveal');

function responsiveCommitments(breakItem) {
    var $carouselInner = $('#carouselInner');
    $carouselInner.html('');
    var $carouselItem = $('<div class="carousel-item active"></div>').appendTo($carouselInner);
    var $carouselItemRow = $('<div class="row"></div>').appendTo($carouselItem);
    var totalItems = Math.ceil(workAreas.length/breakItem)*breakItem;
    var scrollRevealDelay = 0;

    for (var x = 0; x < totalItems; x++) {
        var $newItem = $('<div class="col work-area"></div>').appendTo($carouselItemRow);
        if (x < breakItem) {
            $newItem
                .addClass('scroll-reveal')
                .data('delay', scrollRevealDelay);
            scrollRevealDelay += 300;
        }
        if (x < workAreas.length) {
            $newItem.append('<a class="link-working-area" href="'+workAreas[x].link+'"><img class="img-fluid" src="'+workAreas[x].icon+'" alt="'+workAreas[x].name+'"><p class="lead">'+workAreas[x].name+'</p></a>');
            if ((x + 1) % breakItem === 0 && (x+1) !== totalItems) {
                $carouselItem = $('<div class="carousel-item"></div>').appendTo($carouselInner);
                $carouselItemRow = $('<div class="row"></div>').appendTo($carouselItem);
            }
        }
    }
    $('.carousel').carousel()

    // Grab all elements to scroll reveal
    var $scrollElement = $('#workAreasCarousel').find('.scroll-reveal');

    // For each scroll reveal element, set the data attributes as the options
    $.each($scrollElement, function() {
        var opt = $.extend({ mobile: false }, $(this).data());
        sr.reveal(this, opt);
    });
}

function workAreasByWindowWidth(windowWidth) {
    if (windowWidth >= 1200) {
        responsiveCommitments(5);
    } else if (windowWidth >= 992) {
        responsiveCommitments(4);
    } else if (windowWidth >= 768) {
        responsiveCommitments(4);
    } else if (windowWidth >= 576) {
        responsiveCommitments(3);
    } else {
        responsiveCommitments(1);
    }
}

$(document).ready(function(){
    var windowWidth = $(window).width();
    var windowHeight = $(window).height();
    workAreasByWindowWidth(windowWidth);

});

$(window).resize(function(){
    var windowWidth = $(window).width();
    fullHeigthByWindow();
    workAreasByWindowWidth(windowWidth);
 
});


function fullHeigthByWindow() {
    $('.carousel-inner').removeAttr('style');
}

$(document).ready(function() {
    // Get media - with autoplay disabled (audio or video)
    var media = $('video').not("[autoplay='autoplay']");
    var tolerancePixel = 40;

    function checkMedia(){
        // Get current browser top and bottom
        var scrollTop = $(window).scrollTop() + tolerancePixel;
        var scrollBottom = $(window).scrollTop() + $(window).height() - tolerancePixel;

        media.each(function(index, el) {
            var yTopMedia = $(this).offset().top;
            var yBottomMedia = $(this).height() + yTopMedia;

            if(scrollTop < yBottomMedia && scrollBottom > yTopMedia){ //view explaination in `In brief` section above
                if (!$(this).data('alreadyPlayed')) {
                    $(this).get(0).play();
                    $(this).data('alreadyPlayed', true)
                }
            } else {
                $(this).get(0).pause();
            }
        });

        //}
    }
    $(document).on('scroll', checkMedia);
});


//COUNTDOWN

// Set the date we're counting down to
var countDownDate = new Date("Oct 22, 2020 14:00:00 GMT+02:00").getTime();

// Update the count down every 1 second
var x = setInterval(function() {

  // Get todays date and time
  var now = new Date().getTime(); 


  // Find the distance between now and the count down date
  var distance = countDownDate - now;

  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  if (distance >  0) {
    document.getElementsByClassName("countdown")[0].removeAttribute("hidden");
    //document.querySelector(".countdown").removeAttribute("hidden");
    document.getElementById("countdown_days").innerHTML = days;
    document.getElementById("countdown_hours").innerHTML = hours;
    document.getElementById("countdown_minutes").innerHTML = minutes;
    document.getElementById("countdown_seconds").innerHTML = seconds;     
  } else {
    clearInterval(x);
    document.getElementsByClassName("countdown")[0].setAttribute("hidden", null);
  }
}, 1000);

function rand(n){
    // creamos un numero al azar entre 1 y 10 (o cual sea la cantidad de imágenes)
    return(Math.floor(Math.random() * n) + 1 );
}

var cambia_video = new Array();
cambia_video[0] ="/upload/videos/corte1.mp4";
cambia_video[1] ="/upload/videos/corte2.mp4";
cambia_video[2] ="/upload/videos/corte3.mp4";
cambia_video[3] ="/upload/videos/corte4.mp4";

document.getElementById("ia").src = cambia_video[rand(4)-1];

$('video').each(function(){
    if ($(this).is(":in-viewport")) {
        $(this)[0].play();
    } else {
        $(this)[0].pause();
    }
})
$(document).ready(function() {
    // Get media - with autoplay disabled (audio or video)
    var media = $('video').not("[autoplay='autoplay']");
    var tolerancePixel = 40;


    function checkMedia(){
        // Get current browser top and bottom
        var scrollTop = $(window).scrollTop() + tolerancePixel;
        var scrollBottom = $(window).scrollTop() + $(window).height() - tolerancePixel;

        media.each(function(index, el) {
            var yTopMedia = $(this).offset().top;
            var yBottomMedia = $(this).height() + yTopMedia;

            if(scrollTop < yBottomMedia && scrollBottom > yTopMedia){ //view explaination in `In brief` section above
                if (!$(this).data('alreadyPlayed')) {
                    $(this).get(0).play();
                    $(this).data('alreadyPlayed', true)
                }
            } else {
                $(this).get(0).pause();
            }
        });

        //}
    }
    $(document).on('scroll', checkMedia);


    /*
         * Replace all SVG images with inline SVG
         */
    jQuery('img.svg').each(function(){
        var $img = jQuery(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        jQuery.get(imgURL, function(data) {
            // Get the SVG tag, ignore the rest
            var $svg = jQuery(data).find('svg');

            // Add replaced image's ID to the new SVG
            if(typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if(typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass+' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');

    });

    function responsiveCommitments(breakItem) {
        var $carouselInner = $('#carouselInner');
        $carouselInner.html('');
        var $carouselItem = $('<div class="carousel-item active"></div>').appendTo($carouselInner);
        var $carouselItemRow = $('<div class="row"></div>').appendTo($carouselItem);
        var totalItems = Math.ceil(workAreas.length/breakItem)*breakItem;


        for (var x = 0; x < totalItems; x++) {
            var $newItem = $('<div class="col work-area"></div>').appendTo($carouselItemRow);

            if (x < workAreas.length) {
                $newItem.append('<a class="link-working-area" href="'+workAreas[x].link+'"><img class="img-fluid" src="'+workAreas[x].icon+'" alt="'+workAreas[x].name+'"><p class="lead">'+workAreas[x].name+'</p></a>');
                if ((x + 1) % breakItem === 0 && (x+1) !== totalItems) {
                    $carouselItem = $('<div class="carousel-item"></div>').appendTo($carouselInner);
                    $carouselItemRow = $('<div class="row"></div>').appendTo($carouselItem);
                }
            }
        }
        $('.carousel').carousel()


    }

    function workAreasByWindowWidth(windowWidth) {
        if (windowWidth >= 1200) {
            responsiveCommitments(5);
        } else if (windowWidth >= 992) {
            responsiveCommitments(4);
        } else if (windowWidth >= 768) {
            responsiveCommitments(4);
        } else if (windowWidth >= 576) {
            responsiveCommitments(3);
        } else {
            responsiveCommitments(1);
        }
    }
});
